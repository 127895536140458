.dialog{
    width: 976px;
height: 243px;

border-radius: 10px ;


}
.dialog-div{
    width: 876px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding:30px 0px 0px 70px;
}
.dialog-p{
    font-family: Nunito;
font-size: 32px;
font-weight: 800;
line-height: 43.65px;
text-align: left;
margin:0;

}
.dialog-p1{
    font-family: Nunito;
    font-size: 24px;
    font-weight: 300;
    line-height: 32.74px;
    text-align: left;
   
    
}
.dialog-buttons{
    display: flex;
    width: 876px;
height:90px;
background-color:rgba(6, 20, 146, 0.1);
align-items: center;
justify-content: flex-end;
gap: 10px;
padding-right: 70px;





}
.dialog-btn{
    width: 125px;
    height: 44px;
  

    border-radius: 10px;
    border:rgba(6, 20, 146, 1) 1px solid;
    font-family: Nunito;
font-size: 20px;
font-weight: 600;
line-height: 27.28px;
color:rgba(6, 20, 146, 1);


    

}
.dialog-btn1{
    width: 234px;
    height: 44px;
 
    border-radius: 10px ;
    border: none;

    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;
   
    color: white;
    background-color:rgba(6, 20, 146, 1); ;
    

}