*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: Nunito;
}
.Container {
  display: flex;
  flex-direction: column;
  background-color:#061492;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
/* nav bar logo and button */
.nav{
  display: flex; 
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:20px;
  @media screen and (max-width: 474px) {
    padding: 20px 10px ;
  }
}
.nav button{
  display: block;
  border:none;
  border-radius: 10px;
  color: #061492;
  width: 218px;
  height: 56px;
  font-family: Montserrat Alternates;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 40px;
    border-radius: 5px ;
    font-size: 20px;
    }
  @media screen and (max-width: 474px) {
    width: 90px;
    height: 27px;
    font-size: 12px;
    }
}
.logo{
  width: 223px;
  height: 47px;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 40px;
    border-radius: 5px ;
    font-size: 20px;
    }
  @media screen and (max-width: 474px) {
    width:145px;
    height: 28px;
    margin-top: 0px;
  }
}







/* Collecting forms online made simple! */
.main-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 80%;
  text-align: center;
}
.main-text  span {
  font-size: 4vw;
  font-weight: 700;
  line-height: 1.2;
  @media screen and (max-width: 1200px) {
      font-size: 5vw;
  }
  @media screen and (max-width: 576px) {
    font-size: 6vw;
  }
}


/* Our service makes capturing your custom forms easy. */
.subTitle {
  padding: 10px 0px 0px 0px;
  color: white;
  width: 50%;
  font-size: 1.7vw;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 768px) { 
     width: 70%;
     font-size: 2vw;
  }
  @media screen and (max-width: 576px) {
    font-size: 3vw;
  }


}

/* get started input*/
.header-form{
  margin: 20px 0px;
  width: 100%;
}

/* wrapper of get started input */
.wrapper {
  margin: auto;
  width: 35%;
  position: relative;
  @media screen and (max-width: 1200px) {
    width:50%; 
  }
  @media screen and (max-width: 768px) {
    width:70%; 
  }
  @media screen and (max-width: 576px) {
    width:80%; 
  }
}

.wrapper input {
  background: white;
  width: 100%;
  height: 60px;
  border-radius: 40px ;
  @media screen and (max-width: 1200px) {
    height: 50px;
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
  @media screen and (max-width: 474px) {
    height: 40px;
    font-size: 10px;
  }
}

.wrapper button {
  background: rgba(210, 232, 35, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  color:black;
  position: absolute;
  top: 10%;
  right: 10px;
  width: fit-content;
  padding: 10px;
  height: 80%;
  border-radius: 40px;
  font-size: 22px;
  font-weight: 600;
  border:none;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
  @media screen and (max-width: 474px) {
    font-size: 10px;
  }
}
/* images */
.header-img-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 400px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    height: 350px;
  }
  @media screen and (max-width: 992px) {
    height: 300px;
  }
  @media screen and (max-width: 768px) {
    height: 250px;
  }
  @media screen and (max-width: 576px) {
    height: 200px;
  }
  @media screen and (max-width: 474px) {
    height: 170px;
  }
}
.header-img-container .content-img{
  height: 100%;
}
.header-img-container .content-img1{
  height: 100%;
}


/*div with arrow and two buttons*/
.header-img-container .div {
  display: flex;
  flex-direction: column;
  width: 300px;
  position: absolute;
  z-index: 9999;
  right: 22.5%;
  bottom: 9.5%;
  @media screen and (max-width: 1400px) {
    right: 19%;
  }
  @media screen and (max-width: 1200px) {
    width: 270px;
    right: 18.5%;
    bottom: 9.5%;
  }
  @media screen and (max-width: 992px) {
    width: 250px;
    right: 17%;
    bottom: 9%;
  }
  @media screen and (max-width: 850px) {
    right: 11.5%;
    bottom: 9%;
  }
  @media screen and (max-width: 768px) {
    width: 200px;
    right: 14.5%;
  }
  @media screen and (max-width: 650px) {
    width: 200px;
    right: 8.5%;
  }
  @media screen and (max-width: 576px) {
    width: 150px;
    right: 12.5%;
  }
  @media screen and (max-width: 474px) {
    width: 120px;
    right: 12.5%;
    bottom: 8.5%;
  }
  @media screen and (max-width: 425px) {
    right: 8.5%;
  }
  @media screen and (max-width: 385px) {
    right: 4.5%;
  }
}
.header-img-container div .copy-button{
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 40px;
  border:none;
  width: 50%;
  padding: 5px;
  font-size: 30px;
  font-weight: 700;
  cursor:pointer;
  animation-name: example;
  animation-duration: 1.5s;
  animation-iteration-count:infinite;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
  @media screen and (max-width: 474px) {
    font-size: 10px;
  }
}
/* copy icon */
.copy-svg{
  width: 40px;
  height: 40px;
  @media screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 576px) {
    width: 15px;
    height: 15px;
  }
  @media screen and (max-width: 474px) {
    width: 12px;
    height: 12px;
  }
  
}
/* click to see magic div */
.click-magic{ 
  color: black;
  align-self: flex-end;
  background-color: #D2E823;
  border-radius: 8px;
  padding: 5px;
  font-size: 24px;
  font-weight: 700;
  line-height: 48.74px;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 32px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
    line-height: 28px;
  }
  @media screen and (max-width: 474px) {
    font-size: 10px;
    line-height: 24px;
  }
}
/* click to see magic arrow */
.arrow-img{
  align-self:center;
  width: 150px;
  height: 94px;
  @media screen and (max-width: 768px) {
    width: 130px;
    height: 74px;
  }
  @media screen and (max-width: 474px) {
    width: 37.43px;
    height: 33.72px;
  }
}
/* click to see magic arrow */
.squiggle {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  fill: none;
  animation: draw 2s linear forwards infinite;
}
/* copy button animation for desktop*/
@keyframes example {
  0% {
    width: 50%;
  }
  100% {
    width:60%;
  }
}

/* Endpoint copied to clipboard  */
.Endpoint-copied{
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  bottom:-7%;
  color: black;
  background-color: #D2E823;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
    padding: 5px;

  }
  @media screen and (max-width: 768px) {
    font-size: 16px;

  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  
  }
  @media screen and (max-width: 474px) {
    font-size: 10px;

  }
}

/* click to paste here */
.pastebutton{
  width: fit-content;
  height: fit-content;
  position: absolute;
  padding: 5px;
  left: 41.7%;
  top: 23%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20.19px;
  cursor:pointer;
  border-radius: 5px;
  border:none;
  background-color: #2d92c9;
  animation-name: example1;
  animation-duration: 1.5s;
  animation-iteration-count:infinite;
   @media screen and (max-width: 1470px) {
    left: 41%;
    top: 23%;
  }
  @media screen and (max-width: 1370px) {
    left: 40%;
    top: 23%;
  }
  @media screen and (max-width: 1270px) {
    left: 39.5%;
  }
  @media screen and (max-width: 1200px) {
    font-size: 12px;
    left: 40.5%;
    top: 22%;
  }
  @media screen and (max-width: 1130px) {
    left: 39.7%;
  }
  @media screen and (max-width: 1030px) {
    left: 38.7%;

  }
  @media screen and (max-width: 992px) {
    font-size: 10px;
    padding: 2px;
    left: 40.7%;

  }
  @media screen and (max-width: 885px) {
    left: 39%;

  }
  @media screen and (max-width: 750px) {
    left: 38.5%;
  }
  @media screen and (max-width: 700px) {
    font-size: 8px;
    top: 21%;
  }
  @media screen and (max-width: 576px) {
    left: 37.5%;
    top: 20%;
  }
  @media screen and (max-width: 530px) {
    left: 36%;
  }
  @media screen and (max-width: 474px) {
    font-size: 7px;
    left: 37.5%;
    top: 18.5%;
    padding: 2px;
  }
  @media screen and (max-width: 450px) {
    left: 36.5%;
  }
  @media screen and (max-width: 450px) {
    font-size: 6px;
  }
  @media screen and (max-width: 385px) {
    left: 35.5%;
  }
  @media screen and (max-width: 360px) {
    left: 35%;
  }

}
/* click to paste here animation foe desktop*/
@keyframes example1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


/*---------------------------------------------------------------------*/
input {
  color:black;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.28px;
  padding-left: 40px;
  border:none;
}

input::placeholder {
  color: rgba(205, 208, 233, 1);
}
.header-img-container .div1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 20%;
  position: absolute;
  z-index: 9999;
  right: 25%;
  top: 18%;
  @media screen and (max-width: 1400px) {
    width: 25%;
    right: 18%;
  }
  @media screen and (max-width: 800px) {
    top: 10%;
    
  }
  @media screen and (max-width: 700px) {
    width: 30%;
    right: 10%;
  }
  
  @media screen and (max-width: 480px) {
    width: 35%;
    right: 8%;
    top: 5%;
  }

}
/* Click to fill form div */
.click-form{ 
  text-align: center;
  color: black;
  background-color: #D2E823;
  border-radius: 8px;
  padding: 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 48.74px;
  width: 70%;
  height: fit-content;
  @media screen and (max-width: 1400px) {
    font-size: 18px;
  }
  @media screen and (max-width: 992px) {
    line-height: 40.74px;
    font-size: 16px;
  }
  @media screen and (max-width: 900px) {
    line-height: 32px;
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    line-height: 32px;
    font-size: 12px;
  }
  @media screen and (max-width: 700px) {
    padding: 2px;
  }
  @media screen and (max-width: 576px) {
    font-size: 10px;
  }
  @media screen and (max-width: 480px) {
    font-size: 8px;
  }
}
/* Click to fill form arrow */
.arrow{
  align-self:flex-start;
  width: 30%;
}
.header-img-container .div2 {
  display: flex;
  flex-direction: row-reverse;
  width: 20%;
  position: absolute;
  z-index: 9999;
  right: 23%;
  bottom: 0%;
  @media screen and (max-width: 1200px) {
    width: 25%;
    right: 15%;
  }
  @media screen and (max-width: 992px) {
    bottom: -3%;
  }
  @media screen and (max-width: 768px) {
    bottom: -8%;
  }

  @media screen and (max-width: 630px) {
    width: 30%;
    right: 10%;
  }
  @media screen and (max-width: 576px) {
    bottom: -12%;
  }
  @media screen and (max-width: 474px) {
    bottom: -18%;
  }
}


/* click to see magic arrow animation*/
@keyframes draw {
  to {
    stroke-dashoffset: 0;
    fill:black;
  }
}
/* Click to fill form arrow*/
.squiggle1 {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  fill: none;
  animation: draw1 2s linear forwards infinite;
}
/* Click to fill form arrow animation*/
@keyframes draw1 {
  to {
    stroke-dashoffset: 0;
    fill:black;
  }
}
/* Click to Submit arrow */
.squiggle2 {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  fill: none;
  animation: draw2 2s linear forwards infinite;
}
/* Click to Submit arrow animation*/
@keyframes draw2 {
  to {
    stroke-dashoffset: 0;
    fill:black;
  }
}

.header-img-container .div3{
  position: absolute;
  background-color: #061492;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 10px;
  top: 8%;
  left: 39%;
  gap: 2px;
  width: 13%;
  font-size: 12px;
  @media screen and (max-width: 1400px) {
    width: 15%;
    left: 38%;
  }
  @media screen and (max-width: 1200px) {
    width: 17%;
    left: 37%;
  }
  @media screen and (max-width: 992px) {
    width: 19%;
    font-size: 10px;
  }
  @media screen and (max-width: 768px) {
    width: 21%;
    font-size: 8px;
    padding: 5px;
  }
  @media screen and (max-width: 576px) {
    width: 25%;
  }
  @media screen and (max-width: 474px) {
    width: 30%;
    left: 32%;
  }
}
.header-img-container .div3 button{
  background-color: #D2E823;
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  @media screen and (max-width: 992px) {
    padding: 2px;
    font-size: 10px;
  }
}

.clickable{
  cursor: pointer;
}
/* block or none */
.hidden{
  display: none;
}
.visible{
  display: block;
}
