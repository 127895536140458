.footer {
  background-image: url("assets/footerBackground.svg");
  background-position: 0px -340px;
  background-size: cover;
  /* width: 1520px; */
  /* height: 100vh; */
  padding: 180px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 474px) {
    height: 400px;
    background-size: contain;
  }
}
.footerNav {
  width: 70%;
  display: flex;
  gap: 180px;
  @media screen and (max-width: 474px) {
    flex-direction: column;
    width: 90%;
    gap: 0px;
  }
}
.footer-form {
  @media screen and (max-width: 474px) {
    width: 100%;
  }
}
.footerNav {
  width: 70%;
  display: flex;
  gap: 180px;
  @media screen and (max-width: 474px) {
    flex-direction: column;
    width: 90%;
    gap: 0px;
  }
}
.footerUl {
  list-style-type: none;
  display: inline-flex;
  margin-left: -30px;

  gap: 45px;
  @media screen and (max-width: 474px) {
    margin-left: 0px;
  }
}
.footerUl1 li img {
  width: 35px;
  height: 25px;
  @media screen and (max-width: 474px) {
    width: 25px;
    height: 20px;
  }
}
.footerUl li {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  @media screen and (max-width: 474px) {
    font-size: 12px;
  }
}
.formFooter {
  display: flex;
  background-color: #cde026;
  width: 70%;
  height: 30%;
  border-radius: 10px;
  @media screen and (max-width: 474px) {
    flex-direction: column;
    width: 90%;
    height: 216px;
    padding: 30px;
  }
}
.footerimg {
  justify-content: center;
  align-items: center;
  display: flex;
  @media screen and (max-width: 600px) {
    width: '100%';
  }
}
.footerP {
  justify-content: center;
  align-items: left;
  display: flex;
  flex-direction: column;
  width: 431px;
  height: 216px;
  padding: 30px;
}
.footerimg img {
  width: 70%;
  height: 90px;
}
.footerP {
  justify-content: center;
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  @media screen and (max-width: 474px) {
    align-items: center;
    margin-left: 0px;
  }
  /* text-align: left; */
}
.footerP1 {
  width: 200px;
}
.footerP2 {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 28px; */
  text-align: left;
  width: 306px;
  @media screen and (max-width: 474px) {
    width: 258px;
    height: 32px;
    font-family: Nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
}
