.cards {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-top: 35px;
   @media screen and (max-width: 474px) {
      gap:10px;
   }
}

.card {
    flex: 0 1 33.3333%;
    justify-content: center;
    align-items: center;
    display: flex;
   flex-direction: column;
}
.centered{
   margin:0 auto;
}
.centered .cards .card img{
   width: 150px;
   height: 150px;
   @media screen and (max-width: 474px) {
   width: 100px;
  height: 125px;
   }
}
.centered .cards .card p{
   @media screen and (max-width: 474px) {
      margin: 0
      ;
   
   
   }
}