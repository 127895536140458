/*--------------- first div after header div ------------------*/
.firstDiv {
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: 474px) {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}
.firstDiv-P {
  font-family: Nunito;
  font-size: 38px;
  font-weight: 700;
  line-height: 40.47px;
  text-align: center;
  @media screen and (max-width: 474px) {
    width: 311px;
    height: 66px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.74px;
    text-align: center;
  }
}
.slider {
  background: white;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
    @media screen and (max-width: 474px) {
      height: 50px;
      width: 100px;
    }
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
    @media screen and (max-width: 474px) {
      width: calc(125px * 7);
    }
  }

  .slide {
    height: 100px;
    width: 250px;
    @media screen and (max-width: 474px) {
      height: 50px;
      width: 125px;
    }
  }
}
  
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
/* --------------------------second div styles ------------------------*/
.secondDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e9c0e9;
  padding: 100px 50px;
  @media screen and (max-width: 474px) {
    padding: 100px 0px;
  }
}
.secondDiv-div-P {
  font-family: Nunito;
  font-size: 3vw;
  font-weight: 700;
  color: rgba(80, 34, 116, 1);
  @media screen and (max-width:1200px) {
    font-size: 3.5vw;
  }
  @media screen and (max-width:992px) {
    font-size: 4vw;
  }
  @media screen and (max-width:768px) {
    font-size: 5vw;
  }
  @media screen and (max-width: 576px) {
    font-size: 6vw;
  }
  @media screen and (max-width: 474px) {
    font-size: 7vw;
  }

}
.secondDiv-div-P2 {
  font-size: 2vw;
  font-weight: 500;
  line-height: 80.38px;
  color: rgba(80, 34, 116, 1);
  @media screen and (max-width:1200px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width:992px) {
    font-size: 3vw;
  }
  @media screen and (max-width:768px) {
    font-size: 4vw;
  }
  @media screen and (max-width: 576px) {
    font-size: 5vw;
  }
  @media screen and (max-width: 474px) {
    font-size: 6vw;
    line-height: 70.38px;
  }

}
.secondDiv-div-btn {
  padding: 5px 15px;
  background-color: rgba(80, 34, 116, 1);
  cursor: pointer;
  border-radius: 53px;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  text-align: center;
  border: none;
  color: white;
  @media screen and (max-width: 474px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.37px;
    border-radius: 32px;
  }
}
.secondDiv-div-row {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 474px) {
    flex-direction: column;
    gap: 40px;
  }
}
.secondDiv-div-row-div {
  padding: 30px;
  width: 395px;
  height: 309.97px;
  border-radius: 30px;
  background-color: white;

  @media screen and (max-width: 474px) {
    width: 329px;
    height: 235px;
    padding: 15px;
  }
}
.secondDiv-div-row-div-img {
  width: 72px;
  height: 71.3px;
  margin-bottom: 20px;
  @media screen and (max-width: 474px) {
    width: 56px;
    height: 56px;

    margin-bottom: 0px;
  }
}
.secondDiv-div-row-div-P {
  font-family: Nunito;
  font-size: 25px;
  font-weight: 600;
  line-height: 36.19px;
  text-align: left;
  margin: 0px;

  @media screen and (max-width: 474px) {
    font-size: 22px;

    line-height: 32.74px;
  }
}
.secondDiv-div-row-div-P2 {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 500;
  line-height: 30.74px;
  text-align: left;
  margin: 0px;

  @media screen and (max-width: 474px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 27.28px;
    text-align: left;
  }
}
/*---------------------------- third div styles----------------------------- */
  .thirdDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(210, 232, 35, 1);
    padding: 100px 150px;
    @media screen and (max-width: 992px) {
      align-items: center;
      padding: 40px 90px;
    }
    @media screen and (max-width: 768px) {
      align-items: center;
      padding: 40px 40px;
    }
  }
  .thirdDiv-P {
    font-family: Nunito;
    font-size: 38px;
    font-weight: 700;
    line-height: 70.21px;
    text-align: left;
    width: 100%;
    height: auto;
    margin: 0px;
    @media screen and (max-width: 474px) {
      font-size: 28px;
      font-weight: 700;
      line-height: 38.19px;
    }
  }
  .thirdDiv-P2 {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 500;
    line-height: 36.38px;
    min-width: 100%;
    @media screen and (max-width: 474px) {
      font-size: 20px;
      font-weight: 500;
      line-height: 27.28px;
    }
  }
  .thirdDiv-nav {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 40px;
    overflow-x: scroll;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 10px;

  }
  .thirdDiv-nav::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }
  
  .thirdDiv-nav::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent;
  }
  
  .thirdDiv-nav-button {
    outline: none;
    border: none;
    border-radius: 40px;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32.74px;
    background-color: transparent;
    cursor: pointer;
    min-width: 250px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .thirdDiv-nav-button.active {
    background-color: black; 
    color: white;
  }
  
  @media screen and (max-width: 474px) {
    .thirdDiv-nav-button {
      font-size: 12px;
      min-width: 150px;
    }
  }
  .thirdDiv-img {
    height: 700px;
    width: 100%;
    position: relative;
    margin-top: 30px;
    @media screen and (max-width:1400px){
      height: 600px;
    }
    @media screen and (max-width:1300px){
      height: 550px;
    }
    @media screen and (max-width:1200px){
      height: 500px;
    }
    @media screen and (max-width:1200px){
      height: 450px;
    }
    @media screen and (max-width:1100px){
      height: 400px;
    }
    @media screen and (max-width:992px){
      height: 450px;
    }
    @media screen and (max-width:900px){
      height: 400px;
    }
    @media screen and (max-width:800px){
      height: 350px;
    }
    @media screen and (max-width:700px){
      height: 300px;
    }
    @media screen and (max-width:600px){
      height: 250px;
    }
    @media screen and (max-width:500px){
      height: 200px;
    }
    @media screen and (max-width:400px){
      height: 150px;
    }
  }
  
  .thirdDiv-img-web {
    width: 100%;
    display: block;
  }

  .thirdDiv-img-button {
    position: absolute;
    min-width: 250px;
    height: 60px;
    border-radius: 40px;
    font-family: Montserrat Alternates;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    background-color: black;
    color: white;
    left: 5%;
    bottom: -10%;
    cursor: pointer;
    border: none; 
    /* @media screen and (max-width: 1500px) {
      bottom: 17%;
    }
    @media screen and (max-width: 1200px) {
      bottom: 10%;
      min-width: 150px;
    }
    @media screen and (max-width: 992px) {
      bottom: 20%;
      min-width: fit-content;
      height: fit-content;
      padding: 10px;
    }
    @media screen and (max-width: 768px) {
      bottom: 10%;
    }
    @media screen and (max-width: 700px) {
      bottom: 8%;
      font-size: 16px;
    }
    @media screen and (max-width: 600px) {
      bottom: 5%;
    }
    @media screen and (max-width: 550px) {
      bottom: 13%;
    }
    @media screen and (max-width: 500px) {
      bottom: 5%;
      padding: 5px 10px;
      font-size: 12px;
    }
    @media screen and (max-width: 450px) {
      bottom:15%;
    }
    @media screen and (max-width: 400px) {
      bottom:5%;
    } */
    /* @media screen and (max-width: 100px) {
      bottom: 20px;  
      left: 35px;  
      width: 180px;
      height: 45px;
    }
    @media screen and (max-width: 767px) {
      width: fit-content;
      height: fit-content;
      font-size: 20px;
      padding: 5px;
      bottom: 10px;  
      left: 30px; 
    }
  
    @media screen and (max-width: 576px) {
      font-size: 12px;
      left: 10px; 
      bottom:5px;
    } */
  }
  
/*---------------------------- fourth div styles----------------------------- */
  .forthDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 150px 0px;
    @media screen and (max-width: 474px) {
      padding: 50px 00px;
    }
  }
  .forthDiv-P {
    font-family: Nunito;
    font-size: 52px;
    font-weight: 700;
    line-height: 70.21px;
    text-align: center;
    width: 80%; 
    @media screen and (max-width: 474px) {
      font-size: 26px;
      font-weight: 700;
      line-height: 35.46px;
    }
  }
  .forthDiv-P2 {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 500;
    line-height: 36.38px;
    text-align: center;
    width: 90%;
    margin: 0px;
    @media screen and (max-width: 474px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 32.74px;
    }
  }
  .forthDiv-button {
    width: 218px;
    height: 56px;
    border-radius: 53px;
    font-family: Montserrat Alternates;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: center;
    background-color: #e9c0e9;
    border: none;
    color: black;
    cursor: pointer;
    @media screen and (max-width: 474px) {
      width: 111px;
      height: 32px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.37px;
      border-radius: 32px;
    }
  }

  /*---------------------------- fifth div styles----------------------------- */
  .fifthDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(37, 79, 26, 1);
    padding: 100px 100px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      justify-content: flex-start;
      padding : 100px 0px
    }
  }
  .fifthDiv .content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    @media screen and (max-width: 1200px) {
      width: 90%;
      align-items: center;
    }

  }
  .fifthDiv-btn {
    width: 135px;
    height: 33px;
    border-radius: 12px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.82px;
    background-color: rgba(210, 232, 35, 1);
    display: flex;
    align-items: center;
    border: none;
    @media screen and (max-width: 474px) {
      width: 135px;
      height: 25px;
      border-radius: 12px;
    }
  }
  .fifthDiv-P {
    font-family: Nunito;
    font-size: 52px;
    font-weight: 700;
    line-height: 70.21px;
    color: rgba(210, 232, 35, 1);
    width: 100%;
    margin: 0px;
    @media screen and (max-width: 1200px) {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 38.19px;
    }
  }
  .fifthDiv-P2 {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 500;
    line-height: 36.38px;
    width: 100%;
    color: rgba(210, 232, 35, 1);
    margin: 0px;
    @media screen and (max-width: 1200px) {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 32.74px;
    }
  }
  .sliders {
    width: 45%;
    display: flex;
    align-items: flex-end;
    gap: 20px;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
  @keyframes scroll1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50px * 14));
    }
  }
  
  .slider1 {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 55px;
    padding: 0px;
    border-radius: 71px;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
    }
    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }
    &::before {
      left: 0;
      top: 0;
    }
    .slide-track1 {
      animation: scroll1 10s linear infinite;
      display: flex;
      gap: 80px;
    }
    .slide1 img {
      height: 45px;
      width: 75px;
    }
  }
  .slider2 {
    background: white;
    overflow: hidden;
    position: relative;
    width: 90%;
    height: 55px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 71px;
    @media screen and (max-width: 1200px) {
      width: 500px;
      border-radius: 0px;
      width: 100%;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
    }
    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }
    &::before {
      left: 0;
      top: 0;
    }
    .slide-track2 {
      animation: scroll2 8s linear infinite;
      display: flex;
      gap: 80px;
      @media screen and (max-width: 1200px) {
        gap: 60px;
      }
    }
    .slide2 img {
      height: 45px;
      width: 75px;
      @media screen and (max-width: 1200px) {
        height: 45px;
        width: 75px;
      }
    }
  }
  @keyframes scroll2 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50px * 12));
    }
  }
  /*---------------------------- sixth div styles----------------------------- */
  .sixDiv {
    display: flex;
    background-color: rgba(120, 0, 22, 1);
    gap: 20px;
    padding: 100px 100px;
    @media screen and (max-width: 820px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
     
    }
    @media screen and (max-width: 576px) {
      padding: 100px 20px;
    }
  }
  .sixDiv1 {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media screen and (max-width: 820px) {
      width: 100%;
    }
  }
  .sixDiv1-P {
    font-family: Nunito;
    font-size: 70px;
    font-weight: 700;
    line-height: 75.21px;
    color: rgba(233, 192, 233, 1);
    width: 100%;
    text-align: left;
    margin: 0px;
    @media screen and (max-width: 1200px) {
      font-size: 60px;
      font-weight: 700;
      line-height: 75.21px;
      text-align: center;
    } 
  }
  .sixDiv2 {
    width: 60%;
    display: flex;
    @media screen and (max-width: 820px) {
      width: 100%;
    }
  }
  ul {
    list-style-type: none;
    width: 100%;
    padding: 0px;
  }
  .sixDiv2-li {
    width: 100%;
    padding: 22px 32px;
    border-radius: 40px;
    margin-bottom: 12px;
    background-color: rgba(81, 0, 15, 1);
    font-family: Nunito;
    font-size: 22px;
    font-weight: 600;
    line-height: 35.46px;
    text-align: left;
    color: rgba(233, 192, 233, 1);
    cursor: pointer;
  }
  
  .ldetails {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 32.74px;
    text-align: left;
    display: block;
    padding-top: 0;
    overflow: hidden;
    width: 100%;
    height: 0; 
    transition: height 1s ease, padding-top 0.5s ease;
    @media screen and (max-width: 474px) {
      font-size: 14px;
    }
  }
  
  .ldetails1 {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 32.74px;
    text-align: left;
    border-top: #e9c0e9 0.5px solid;
    padding-top: 10px;
    overflow: hidden;
    height: fit-content;
    transition: height 1s ease, padding-top 0.5s ease;
    display: block;
    @media screen and (max-width: 474px) {
      padding-top: 5px;
      font-size: 14px;
      line-height: 16.74px;
    }
  }
::-webkit-scrollbar {
  display: none;
}


