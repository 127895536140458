    .exampleForm {
        width: 500px;
        margin: 0 auto; 
        font-family: "Nunito";
    }
    .exampleForm label {
        display: block;
        margin-bottom: 5px;
    }

    .exampleForm input[type="text"],
    .exampleForm input[type="email"],
    .exampleForm textarea {
        width: 100%;
        padding:15px;
        outline: 1px solid #E0E5F2;
        height: 34px;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 10px;
        resize: none;
        font-size: 16px;
        color: black;
    
    }
    .exampleForm textarea {
       height: 100px;
    }
    .exampleForm input::placeholder,
    .exampleForm textarea::placeholder{
        font-size: 16px;
        color: #999999;
    }
    .exampleForm input:focus,
    .exampleForm input:active,
    .exampleForm textarea:focus,
    .exampleForm textarea:active{
      outline: 1px solid darkBlue;
    }
    .exampleForm input[type="submit"] {
        background-color: darkblue;
        width: 100%;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .exampleForm input[type="submit"]:hover {
        opacity: 0.8;
        transform: scale(0.99);
    }
    @media only screen and (max-width: 768px) {
        .exampleForm {
            width: 100%;
        }
      }